<template>
  <div class="main">
    <Loading v-show="state === ViewState.Loading" />
    <div v-if="state === ViewState.Loaded">
      <!-- 災害 -->
      <div class="alert alert-danger text-center p-3" role="alert">
        <p class="mb-1"><b>{{ response.current.disaster.title }}</b></p>
        <p class="small"><b>{{ formatDate(response.current.disaster.start_at) }} 〜</b></p>
        <div class="text-center">
          <b-button variant="danger" :to="{ name: 'disaster_entry_sheet_create' }">WEB事前登録はこちら</b-button>
        </div>
      </div>
      <!-- MAP & 避難所 -->
      <b-container fluid>
        <b-row>
          <b-col xs="12" md="8" class="px-0">
            <MapPane class="map"
              :center="[response.current.municipality.latitude, response.current.municipality.longitude]"
              :disaster_shelters="response.current.disaster.disaster_shelters" :active_shelter.sync="active_shelter"
              @onMapMarkerClick="onMapMarkerClick">
            </MapPane>
          </b-col>
          <b-col xs="0" md="4" class="list-padding">
            <div id="list-group" class="list-group list-max-height scrollable">
              <b-list-group-item v-for="(disaster_shelter, index) in response.current.disaster.disaster_shelters"
                :id="'list-item-' + disaster_shelter.id" :key="index"
                :active="disaster_shelter.id === active_shelter.id" @click="onListItemClick(disaster_shelter)">
                <b-row>
                  <b-col>
                    <p class="my-2"><b>{{ disaster_shelter.shelter.name }}</b></p>
                    <p class="mb-0 small">{{ disaster_shelter.shelter.address }}</p>
                    <p class="mb-3 small">{{ disaster_shelter.shelter.phone_number }}</p>
                  </b-col>
                  <b-col cols="auto">
                    <div style="min-width: 100px;">
                      <img :src="toEvacueeIcon(disaster_shelter).image" type="image/svg+xml" class="rounded" />
                    </div>
                  </b-col>
                </b-row>
                <p class="mb-0 small"><b>現在の収容人数</b></p>
                <p class="mb-0 small"><b>{{ disaster_shelter.number_of_evacuees.toLocaleString() }}人 / {{
                    disaster_shelter.capacity.toLocaleString()
                }}人 ({{ disaster_shelter.evacuee_rate.toLocaleString()
}}%)</b></p>
                <div class="d-flex flex-wrap mt-2">
                  <div v-for="(facility, index) in disaster_shelter.shelter.facilities" :key="index"
                    class="bg-white border border-secondary rounded mr-1 mb-1 px-2">
                    <img :src="facility.image_url" class="facility-img mb-1 mr-1">
                    <span class="small" style="color: black;">{{ facility.name }}</span>
                  </div>
                </div>
              </b-list-group-item>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <!-- WEB事前登録はこちら -->
      <div class="text-center mb-5">
        <b-button class="mt-5" variant="danger" :to="{ name: 'disaster_entry_sheet_create' }">WEB事前登録はこちら</b-button>
      </div>
    </div>
    <div v-if="state === ViewState.Fatal">
      <div class="text-center mt-5">
        <h4>{{ state.title }}</h4>
        <p>{{ state.message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import MapPane from "@/components/MapPane.vue";
import DateUtils from '@/commons/utils/DateUtils'
import EvacueeIcon from '@/commons/enums/EvacueeIcon'

const ViewState = {
  Default: {},
  Loading: {},
  Loaded: {},
  Fatal: { title: 'エラー', message: '恐れ入りますが、しばらく経ってから再度お試しください' },
}

export default {
  mixins: [DateUtils, EvacueeIcon],
  data() {
    return {
      ViewState: ViewState,
      state: ViewState.Default,
      active_shelter: {
        id: null,
      },
      response: {
        current: {
          municipality: null,
          disaster: null
        }
      },
    };
  },
  components: {
    Loading,
    MapPane,
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.state = ViewState.Loading
      await this.axios.get(`/api/disasters/current`).then((response) => {
        this.response.current = response.data
        this.state = ViewState.Loaded
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: 'not_found' })
        } else {
          this.state = ViewState.Fatal
        }
      })
    },
    onListItemClick(disaster_shelter) {
      this.active_shelter = disaster_shelter
    },
    onMapMarkerClick(disaster_shelter) {
      this.active_shelter = disaster_shelter
      this.$scrollTo('#list-item-' + disaster_shelter.id, 1000, { container: '#list-group' })
    }
  },
}
</script>

<style scoped>
.main {
  margin: 20px;
}

.list-group-item.active {
  background-color: #B2CBE4;
  border-color: #B2CBE4;
}

.list-group {
  max-height: 600px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.v-center {
  display: flex;
  align-items: center;
}

.square {
  aspect-ratio: 1;
  background-color: #fafafa;
}

.square>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.square>img.selected {
  opacity: 0.8;
}

.list-max-height {
  max-height: 600px;
}

.mapPane {
  z-index: 0;
  height: 600px;
  text-align: left;
}

.facility-img {
  width: 14px;
  height: 14px;
  object-fit: cover;
}

@media (max-width: 575.98px) {
  .map {
    height: 250px;
  }

  .list-padding {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .map {
    height: 250px;
  }

  .list-padding {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .map {
    height: 600px;
  }

  .list-padding {
    padding-right: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .map {
    height: 600px;
  }

  .list-padding {
    padding-right: 0px;
  }
}

@media (min-width: 1200px) {
  .map {
    height: 600px;
  }

  .list-padding {
    padding-right: 0px;
  }
}
</style>
