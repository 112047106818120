<template>
  <div class="mapPane">
    <l-map :zoom="zoom" :center="center">
      <!-- レイヤコントロール -->
      <l-control-layers position="topright"></l-control-layers>
      <!-- レイヤ設定 -->
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"
      ></l-tile-layer>
      <!-- マーカ -->
      <l-marker
        v-for="disaster_shelter in disaster_shelters"
        :key="disaster_shelter.id"
        :lat-lng="markerLocation(disaster_shelter)"
        :options="{ riseOnHover: true }"
        :zIndexOffset="disaster_shelter.id === active_shelter.id ? 1000 : 0"
        @click="onMarkerClick(disaster_shelter)"
      >
        <l-icon
          :icon-size="dynamicSize"
          :icon-anchor="dynamicAnchor"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="50" width="40">
            <g transform="translate(-814.59595,-274.38623)">
              <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
                <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" :style="pinStyle(disaster_shelter, active_shelter)" />
                <circle r="3.0355" cy="288.25278" cx="823.03064" id="path3049" style="display:inline;fill:#590000};"/>
              </g>
            </g>
          </svg>
        </l-icon>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { Icon } from 'leaflet';
import {
  LMap,
  LTileLayer,
  LControlLayers,
  LMarker,
  LIcon
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import EvacueeIcon from '@/commons/enums/EvacueeIcon'

// デフォルトマーカーアイコン設定
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: "MapPane",
  mixins: [EvacueeIcon],
  components: {
    LMap,
    LTileLayer,
    LControlLayers,
    LMarker,
    LIcon
  },
  props: {
    center: {
      type: Array,
      required: true
    },
    disaster_shelters: {
      type: Array,
      required: false
    },
    active_shelter: {
      required: false
    },
  },
  data() {
    return {
      zoom: 13,
      tileProviders: [
        {
          name: 'GSI',
          visible: true,
          url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
          attribution: "Maptiles by <a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
        },
        {
          name: 'OSM',
          visible: false,
          url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution: "© <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors, <a href='http://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA"
        }
      ],
      iconSize: 40
    }
  },
  methods: {
    onMarkerClick(disaster_shelter) {
      if (this.active_shelter.id == disaster_shelter.id) {
        // なぜかスマホだとClickイベントが2回発行されるので回避用の処理。
        // 1回目でactive_shelterが更新されるので2回目ではactive_shelterのidが一致するためここでスキップできる。
        return
      }
      this.$emit('onMapMarkerClick', disaster_shelter)
    },
  },
  computed: {
    dynamicSize: function () {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor: function () {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
    markerLocation: function () {
      return function (disaster_shelter) {
        return [disaster_shelter.shelter.latitude, disaster_shelter.shelter.longitude]
      }
    },
    pinStyle: function () {
      return (disaster_shelter,active_shelter) => {
        if (disaster_shelter.id === active_shelter.id) {
          return "fill: #B2CBE4;stroke: #000000;}"
        } else {
          return this.toEvacueeIcon(disaster_shelter).style
        }
      }
    },
  },
};
</script>

<style scoped>
.mapPane {
  z-index: 0;
  height: 400px;
  text-align: left;
}
</style>