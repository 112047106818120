/**
 * 混雑状況
 */
const EvacueeIcon = {
  Full: { threshold: 99, style: 'fill: #FF0000;stroke: #FF0000;}', name: '満員', image: '/images/congestion01.svg', cellClass: 'bg-full' },
  Crowded: { threshold: 65, style: 'fill: #FF7800;stroke: #FF7800;}', name: '混雑', image: '/images/congestion02.svg', cellClass: 'bg-crowded' },
  Usually: { threshold: 30, style: 'fill: #00FF00;stroke: #00FF00;}', name: 'やや余裕', image: '/images/congestion03.svg', cellClass: 'bg-usually' },
  Vacancy: { threshold: 0, style: 'fill: #002EC6;stroke: #002EC6;}', name: '余裕あり', image: '/images/congestion04.svg', cellClass: 'bg-vacancy' },
}
export default {
  data() {
    return {
      EvacueeIcon: EvacueeIcon,
    }
  },
  methods: {
    toEvacueeIcon: function (p) {
      for (var key of Object.keys(EvacueeIcon)) {
        const e = EvacueeIcon[key]
        if (p.evacuee_rate >= e.threshold) return e
      }
      return EvacueeIcon.Vacancy
    },
  }
}