var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mapPane" },
    [
      _c(
        "l-map",
        { attrs: { zoom: _vm.zoom, center: _vm.center } },
        [
          _c("l-control-layers", { attrs: { position: "topright" } }),
          _vm._l(_vm.tileProviders, function(tileProvider) {
            return _c("l-tile-layer", {
              key: tileProvider.name,
              attrs: {
                name: tileProvider.name,
                visible: tileProvider.visible,
                url: tileProvider.url,
                attribution: tileProvider.attribution,
                "layer-type": "base"
              }
            })
          }),
          _vm._l(_vm.disaster_shelters, function(disaster_shelter) {
            return _c(
              "l-marker",
              {
                key: disaster_shelter.id,
                attrs: {
                  "lat-lng": _vm.markerLocation(disaster_shelter),
                  options: { riseOnHover: true },
                  zIndexOffset:
                    disaster_shelter.id === _vm.active_shelter.id ? 1000 : 0
                },
                on: {
                  click: function($event) {
                    return _vm.onMarkerClick(disaster_shelter)
                  }
                }
              },
              [
                _c(
                  "l-icon",
                  {
                    attrs: {
                      "icon-size": _vm.dynamicSize,
                      "icon-anchor": _vm.dynamicAnchor
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 34.892337",
                          height: "50",
                          width: "40"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              transform: "translate(-814.59595,-274.38623)"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  transform:
                                    "matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)"
                                }
                              },
                              [
                                _c("path", {
                                  style: _vm.pinStyle(
                                    disaster_shelter,
                                    _vm.active_shelter
                                  ),
                                  attrs: {
                                    d:
                                      "m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z"
                                  }
                                }),
                                _c("circle", {
                                  staticStyle: {
                                    display: "inline",
                                    fill: "#590000}"
                                  },
                                  attrs: {
                                    r: "3.0355",
                                    cy: "288.25278",
                                    cx: "823.03064",
                                    id: "path3049"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }