var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("Loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.state === _vm.ViewState.Loading,
            expression: "state === ViewState.Loading"
          }
        ]
      }),
      _vm.state === _vm.ViewState.Loaded
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "alert alert-danger text-center p-3",
                  attrs: { role: "alert" }
                },
                [
                  _c("p", { staticClass: "mb-1" }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.response.current.disaster.title))
                    ])
                  ]),
                  _c("p", { staticClass: "small" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(_vm.response.current.disaster.start_at)
                        ) + " 〜"
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "danger",
                            to: { name: "disaster_entry_sheet_create" }
                          }
                        },
                        [_vm._v("WEB事前登録はこちら")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "px-0", attrs: { xs: "12", md: "8" } },
                        [
                          _c("MapPane", {
                            staticClass: "map",
                            attrs: {
                              center: [
                                _vm.response.current.municipality.latitude,
                                _vm.response.current.municipality.longitude
                              ],
                              disaster_shelters:
                                _vm.response.current.disaster.disaster_shelters,
                              active_shelter: _vm.active_shelter
                            },
                            on: {
                              "update:active_shelter": function($event) {
                                _vm.active_shelter = $event
                              },
                              onMapMarkerClick: _vm.onMapMarkerClick
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "list-padding",
                          attrs: { xs: "0", md: "4" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "list-group list-max-height scrollable",
                              attrs: { id: "list-group" }
                            },
                            _vm._l(
                              _vm.response.current.disaster.disaster_shelters,
                              function(disaster_shelter, index) {
                                return _c(
                                  "b-list-group-item",
                                  {
                                    key: index,
                                    attrs: {
                                      id: "list-item-" + disaster_shelter.id,
                                      active:
                                        disaster_shelter.id ===
                                        _vm.active_shelter.id
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onListItemClick(
                                          disaster_shelter
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", [
                                          _c("p", { staticClass: "my-2" }, [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  disaster_shelter.shelter.name
                                                )
                                              )
                                            ])
                                          ]),
                                          _c(
                                            "p",
                                            { staticClass: "mb-0 small" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  disaster_shelter.shelter
                                                    .address
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "mb-3 small" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  disaster_shelter.shelter
                                                    .phone_number
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "auto" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "min-width": "100px"
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "rounded",
                                                  attrs: {
                                                    src: _vm.toEvacueeIcon(
                                                      disaster_shelter
                                                    ).image,
                                                    type: "image/svg+xml"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("p", { staticClass: "mb-0 small" }, [
                                      _c("b", [_vm._v("現在の収容人数")])
                                    ]),
                                    _c("p", { staticClass: "mb-0 small" }, [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            disaster_shelter.number_of_evacuees.toLocaleString()
                                          ) +
                                            "人 / " +
                                            _vm._s(
                                              disaster_shelter.capacity.toLocaleString()
                                            ) +
                                            "人 (" +
                                            _vm._s(
                                              disaster_shelter.evacuee_rate.toLocaleString()
                                            ) +
                                            "%)"
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-wrap mt-2" },
                                      _vm._l(
                                        disaster_shelter.shelter.facilities,
                                        function(facility, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass:
                                                "bg-white border border-secondary rounded mr-1 mb-1 px-2"
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "facility-img mb-1 mr-1",
                                                attrs: {
                                                  src: facility.image_url
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "small",
                                                  staticStyle: {
                                                    color: "black"
                                                  }
                                                },
                                                [_vm._v(_vm._s(facility.name))]
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center mb-5" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-5",
                      attrs: {
                        variant: "danger",
                        to: { name: "disaster_entry_sheet_create" }
                      }
                    },
                    [_vm._v("WEB事前登録はこちら")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.state === _vm.ViewState.Fatal
        ? _c("div", [
            _c("div", { staticClass: "text-center mt-5" }, [
              _c("h4", [_vm._v(_vm._s(_vm.state.title))]),
              _c("p", [_vm._v(_vm._s(_vm.state.message))])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }